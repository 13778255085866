@selected-bg-colour: #197dff;
@selected-bg-hover-colour: #81b3ff32;
@selected-btn-bg-hover-colour: #0053c2;
@deactivated-bg-colour: #676a6b;
@deactivated-bg-hover-colour: #8a8c8f;
@disabled-colour: #969696;

@lead-accent: #197dff;
@complementary: #9b51e0;
@success: #27ae60;
@focus-orange: #f29a4b;
@error: #eb5757;
@warning: #f2c94c;
@disabled: #d3d3d3;
@captions: #797979;

@font-face {
  font-family: 'Euclid Circular B';
  src: local('Euclid Circular B Bold'), local('EuclidCircularB-Bold'),
    url('../../assets/fonts/EuclidCircularB-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular B';
  src: local('Euclid Circular B Regular'), local('EuclidCircularB-Regular'),
    url('../../assets/fonts/EuclidCircularB-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.h6 {
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
}

.h5 {
  font-weight: 600;
  font-size: 12px;
}

.mat-typography h3 {
  font-size: 16px;
  font-weight: 700;
}

.body-large {
  font-size: 14px;
  line-height: 20px;
}

.body-medium {
  font-size: 12px;
  line-height: 16px;
}

.body-small {
  font-size: 10px;
}

.non-important-text {
  font-size: 8px;
}

.caption-basic {
  font-size: 10px;
  line-height: 10px;
}

.caption-underline,
.mat-option {
  font-size: 12px;
  line-height: 12px;
}

.mat-mdc-unelevated-button[disabled] {
  color: #ffff !important;
  background-color: #00000042;
}

// MDC Angular Material - Snackbar component

.mat-mdc-snack-bar-container.atlas-snackbar .mdc-snackbar__surface {
  background: @lead-accent;
  color: white;
}

.mat-mdc-snack-bar-container.atlas-snackbar-error .mdc-snackbar__surface {
  background: @error;
  color: white;
}

.mdc-snackbar__label {
  padding: 0;
}

// .mat-stroked-button.mat-button-disabled.mat-button-disabled {
//   color: @disabled-colour;
//   background-color: @disabled;
// }

// .mat-stroked-button:not(.mat-button-disabled) {
//   border-color: unset;
// }

// .mat-snack-bar-container {
//   background: @lead-accent;
//   color: white;
//   padding: 0px;
// }

// .mat-snack-bar-container.atlas-snackbar-error {
//   background: @error;
//   color: white;
// }

// input.mat-input-element {
//   padding-bottom: 0.15em;
// }

// .mat-autocomplete-panel .mat-option {
//   padding: 0px 5px;
//   font-size: 11px;
// }

.mat-mdc-unelevated-button {
  --mat-filled-button-hover-state-layer-opacity: 0;
}

.mdc-text-field--filled {
  background-color: none;
}

.atlas-panel-body {
  overflow-y: auto;
  max-height: calc(25vh);
  padding-left: 20px;
  padding-right: 20px;
}

.atlas-panel-header {
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 5px;
}

.atlas-panel-header-description {
  font-size: 10px;
  line-height: 13px;
  padding-bottom: 15px;
}

@media screen and (max-width: 600px) {
  .atlas-panel-body {
    max-height: calc(60vh);
  }
}

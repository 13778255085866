@import 'themes-override/common-theme.less';

.mat-mdc-form-field {
  width: 100%;
  --mat-form-field-container-text-size: 12px;
}

.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-disabled
  .mat-mdc-form-field-outline
  > .mat-mdc-form-field-outline-start,
.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-disabled
  .mat-mdc-form-field-outline
  > .mat-mdc-form-field-outline-end,
.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-disabled
  .mat-mdc-form-field-outline
  > .mat-mdc-form-field-outline-gap {
  border-width: 2px;
}
.mat-mdc-form-field-appearance-outline
  .mat-mdc-form-field-outline.mat-mdc-form-field-outline-thick
  > .mat-mdc-form-field-outline-start,
.mat-mdc-form-field-appearance-outline
  .mat-mdc-form-field-outline.mat-mdc-form-field-outline-thick
  > .mat-mdc-form-field-outline-end,
.mat-mdc-form-field-appearance-outline
  .mat-mdc-form-field-outline.mat-mdc-form-field-outline-thick
  > .mat-mdc-form-field-outline-gap {
  border-width: 1px;
}

/* Change the border focused color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  --mdc-outlined-text-field-focus-outline-width: 1px;
}

.mdc-list-item__primary-text{
  --mdc-list-list-item-label-text-size: 12px;
}

.mat-list-option,
.mat-nav-list .mat-list-item,
.mat-action-list .mat-list-item {
  &:hover {
    background-color: unset !important;
  }
  &:focus {
    background-color: unset !important;
  }
  & .mat-list-text {
    padding-left: 10px !important;
  }
}

body .mat-expansion-panel {
  padding-left: 5px;
  padding-right: 5px;
}

.mat-mdc-autocomplete-panel {
  max-height: 400px !important;
}

.mat-optgroup-label {
  padding-top: 5px !important;
}

.mat-mdc-stroked-button:hover {
  background-color: @selected-btn-bg-hover-colour;
}

.mat-mdc-unelevated-button:hover:enabled {
  background: @selected-btn-bg-hover-colour !important;
}

body .mat-mdc-unelevated-button.atlas-icon-button {
  width: 40px;
  min-width: 40px;
  height: 36px;
  padding-left: 5px;
  padding-right: 5px;

}

.mat-mdc-unelevated-button.atlas-icon-button > .mat-icon {
  font-size: 24px;
}

.mat-menu-content:not(:empty) {
  padding-bottom: 0px !important;
}

.mat-mdc-unelevated-button.atlas-icon-button.atlas-secondary-icon-button {
  border: @lead-accent solid 1px;
  background-color: transparent;
  color: @lead-accent;
  &:hover {
    background-color: #0069f41a !important;
  }
  &:disabled {
    color: @disabled-colour;
    border: transparent;
    &:hover {
      background-color: #676a6b !important;
    }
  }
}

body .mat-mdc-unelevated-button > .mat-mdc-icon,
.mat-mdc-raised-button > .mat-mdc-icon,
.mat-mdc-outlined-button > .mat-mdc-icon {
  height: fit-content;
  width: fit-content;
  margin-left: 0;
  margin-right: 5px;
}

// Angular 16: remove padding from content because we have padding in parent container.
body .mdc-dialog .mdc-dialog__content {
  padding: 4px 0 0 0;
}

// MDC Angular Material - Menu component
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  background-color: transparent;
}

.mat-mdc-icon-button .mat-ripple:not(:empty).mat-mdc-button-ripple {
  background-color: @selected-btn-bg-hover-colour;
  opacity: 0.2;
}

.mat-mdc-menu-content button.mat-mdc-menu-item .mat-mdc-menu-item-text {
  font-size: 12px;
  font-weight: 400;
}

.mat-mdc-form-field-infix {
  padding: 0px;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 0px;
}
.mat-mdc-raised-button {
  height: 36px !important;
}
.mat-mdc-select-trigger {
  font-size: 12px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-unselected-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-track-color: rgba(0, 0, 0, 0.38);
  --mdc-switch-unselected-track-color: rgba(0, 0, 0, 0.38);
  --mdc-switch-unselected-hover-track-color: rgba(0, 0, 0, 0.38);
}

body .mat-mdc-option {
  --mat-option-label-text-size: 12px;
  min-height: 36px;
}

.mdc-text-field--outlined .mat-mdc-form-field-infix {
  --mat-form-field-container-height: 45px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  --mat-form-field-container-height: 40px;
}


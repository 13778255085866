@font-face {
  font-family: 'atlas-icons';
  src: url('../assets/fonts/icons/atlas.eot?2s7sbo');
  src: url('../assets/fonts/icons/atlas.eot?2s7sbo#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/icons/atlas.ttf?2s7sbo') format('truetype'),
    url('../assets/fonts/icons/atlas.woff?2s7sbo') format('woff'),
    url('../assets/fonts/icons/atlas.svg?2s7sbo#atlas') format('svg');
  font-weight: normal;
  font-style: normal;
}

.atlas-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'atlas-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 20px !important;
  text-transform: none;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-reset:after {
  content: '\e900';
  font-weight: bolder;
}

.icon-street-view:after {
  content: '\e901';
}

.icon-model:after {
  content: '\e902';
}

.icon-circle:after {
  content: '\e903';
}

.icon-poly:after {
  content: '\e904';
}

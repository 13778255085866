.boundary-legend-icon,
.point-legend-icon,
.line-legend-icon {
  display: inline-block;
  vertical-align: middle;
}

.boundary-legend-icon,
.line-legend-icon {
  width: 40px;
  height: 25px;
  padding: 2px;
  border-radius: 3px;
  box-sizing: border-box;
  border: solid;
}

.point-legend-icon {
  width: 14px;
  height: 14px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid;
}

.icon-legend-icon {
  width: 14px;
  height: 15px;
  vertical-align: sub;
}

.line-legend-icon {
  height: 5px;
  border-radius: 0;
  border: none;

}

/* You can add global styles to this file, and also import other style files */

@import '../node_modules/mapbox-gl/dist/mapbox-gl.css';
@import './less/material-override.less';
@import './less/panels.less';
@import './less/layer-legends.less';
@import './less/icon-fonts.less';
@import './less/chip-list-override.less';

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

// As per https://www.mapbox.com/plans/.
// Unless you are on the Standard or Premium pricing plans then the MapBox logo is required according to the terms of service.
// check carto licencing
/*.mapbox-logo {
  display: none;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapbox-improve-map {
  display: none;
}
.mapboxgl-ctrl-compass {
  display: none;
}*/

// this doesn't work when in the layer-legend.less
.legend .mat-expansion-panel-body {
  padding: 4px;
}

.legend .mat-expansion-panel-header.mat-expanded {
  height: 44px;
}

/* scroll bar  styles */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
}

.deck-tooltip.deck-tooltip-triangle-bottom {
  z-index: 2 !important;
}

.deck-tooltip .deck-tooltip-column-left p,
.deck-tooltip .deck-tooltip-column-right p,
.deck-tooltip .deck-wrap-text-tooltip p {
  margin: 0 0 2px;
}

.deck-tooltip.deck-tooltip-triangle-bottom {
  z-index: 2 !important;
}

.deck-tooltip p.deck-left-aligned-tooltip-row {
  padding: 0px;
  margin: 0px;
  text-align: left;
}

.deck-tooltip-row .deck-tooltip .deck-tooltip-column-left {
  flex: 40%;
  padding: 0px;
  text-align: left;
}

.deck-tooltip .deck-tooltip-column-right {
  flex: 60%;
  padding: 0px;
  text-align: right;
}

.deck-tooltip .deck-tooltip-row {
  display: flex;
  align-content: space-between;
}

.deck-tooltip .deck-wrap-text-tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.deck-tooltip-header {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.mat-stroked-button.atlas-icon-button {
  width: 40px;
  min-width: 40px;
  height: 36px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 20px;
}

.multiline-tooltip-wrap {
  overflow: visible;
  word-break: break-all;
}

// This class is used to create the disabled ui effect, use on any container div that needs to be disabled.
// Also use the atlasDisableUI directive on elements that need to be disabled based on a criteria.
.atlas-disable-ui {
  top: 0;
  left: 0;
  opacity: 0.4;
  display: block;
  z-index: 100;
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  .dialog-full-width-height {
    max-width: 100% !important;
    width: 100% !important;
    max-height: 100%;
    height: 100%;
  }

  .dialog-95vw-width {
    max-width: 95vw !important;
    max-height: 100%;
  }
}

// Angular 16 has removed the 24px padding for dialog so we need to add it back in.
.atlas-dialog-container {
  padding: 24px;
  display: block;
}

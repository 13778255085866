@error: #eb5757;

atlas-numeric-chip-list.ng-dirty.ng-invalid .mat-chip.mat-standard-chip,
atlas-numeric-chip-list.ng-dirty.ng-invalid
  .chip-list.mat-form-field-appearance-outline
  .mat-form-field-outline.mat-form-field-outline-thick,
atlas-numeric-chip-list.ng-dirty.ng-invalid
  .mat-form-field-wrapper
  .mat-form-field-label-wrapper
  > .mat-form-field-label,
atlas-numeric-chip-list.ng-dirty.ng-invalid
  .mat-chip.mat-standard-chip
  .mat-chip-remove {
  color: @error;
}

atlas-locator-catchment-chip-list.ng-dirty.ng-invalid
  .mat-chip.mat-standard-chip,
atlas-locator-catchment-chip-list.ng-dirty.ng-invalid
  .chip-list.mat-form-field-appearance-outline
  .mat-form-field-outline.mat-form-field-outline-thick,
atlas-locator-catchment-chip-list.ng-dirty.ng-invalid
  .mat-form-field-wrapper
  .mat-form-field-label-wrapper
  > .mat-form-field-label,
atlas-locator-catchment-chip-list.ng-dirty.ng-invalid
  .mat-chip.mat-standard-chip
  .mat-chip-remove {
  color: @error;
}

atlas-scenario-name-chip-list.ng-dirty.ng-invalid .mat-chip.mat-standard-chip,
atlas-scenario-name-chip-list.ng-dirty.ng-invalid
  .chip-list.mat-form-field-appearance-outline
  .mat-form-field-outline.mat-form-field-outline-thick,
atlas-scenario-name-chip-list.ng-dirty.ng-invalid
  .mat-form-field-wrapper
  .mat-form-field-label-wrapper
  > .mat-form-field-label,
atlas-scenario-name-chip-list.ng-dirty.ng-invalid
  .mat-chip.mat-standard-chip
  .mat-chip-remove {
  color: @error;
}

atlas-numeric-chip-list.ng-dirty.ng-invalid .mat-chip.mat-standard-chip {
  color: @error;
  border-color: @error;
}

atlas-locator-catchment-chip-list.ng-dirty.ng-invalid
  .mat-chip.mat-standard-chip {
  color: @error;
  border-color: @error;
}

atlas-scenario-name-chip-list.ng-dirty.ng-invalid .mat-chip.mat-standard-chip {
  color: @error;
  border-color: @error;
}

atlas-numeric-chip-list:not(ng-dirty.ng-invalid) .chip-list-show-error {
  display: none;
}

atlas-locator-catchment-chip-list:not(ng-dirty.ng-invalid)
  .chip-list-show-error {
  display: none;
}

atlas-scenario-name-chip-list:not(ng-dirty.ng-invalid) .chip-list-show-error {
  display: none;
}

atlas-numeric-chip-list.ng-dirty.ng-invalid .chip-list-show-error {
  display: inherit !important;
  color: @error;
}

atlas-locator-catchment-chip-list.ng-dirty.ng-invalid .chip-list-show-error {
  display: inherit !important;
  color: @error;
}

atlas-scenario-name-chip-list.ng-dirty.ng-invalid .chip-list-show-error {
  display: inherit !important;
  color: @error;
}

atlas-numeric-chip-list .mat-standard-chip .mat-chip-remove,
.mat-standard-chip .mat-chip-trailing-icon {
  margin-left: 0px;
}

atlas-locator-catchment-chip-list .mat-standard-chip .mat-chip-remove,
.mat-standard-chip .mat-chip-trailing-icon {
  margin-left: 0px;
}

atlas-scenario-name-chip-list .mat-standard-chip .mat-chip-remove,
.mat-standard-chip .mat-chip-trailing-icon {
  margin-left: 0px;
}

atlas-numeric-chip-list .mat-chip.mat-standard-chip {
  min-height: 20px !important;
}

atlas-locator-catchment-chip-list .mat-chip.mat-standard-chip {
  min-height: 20px !important;
}

atlas-scenario-name-chip-list .mat-chip.mat-standard-chip {
  min-height: 20px !important;
}

atlas-numeric-chip-list .mat-standard-chip .mat-chip-remove .mat-icon {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

atlas-locator-catchment-chip-list
  .mat-standard-chip
  .mat-chip-remove
  .mat-icon {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

atlas-scenario-name-chip-list .mat-standard-chip .mat-chip-remove .mat-icon {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

atlas-numeric-chip-list .mat-chip {
  font-size: 12px !important;
  font-weight: bold;
}

atlas-locator-catchment-chip-list .mat-chip {
  font-size: 12px !important;
  font-weight: bold;
}

atlas-scenario-name-chip-list .mat-chip {
  font-size: 12px !important;
  font-weight: bold;
}

atlas-numeric-chip-list .mat-standard-chip .mat-chip-remove,
.mat-standard-chip .mat-chip-trailing-icon {
  height: 16px !important;
}

atlas-locator-catchment-chip-list .mat-standard-chip .mat-chip-remove,
.mat-standard-chip .mat-chip-trailing-icon {
  height: 16px !important;
}

atlas-scenario-name-chip-list .mat-standard-chip .mat-chip-remove,
.mat-standard-chip .mat-chip-trailing-icon {
  height: 16px !important;
}

/* https://stackoverflow.com/questions/52805504/single-line-horizontal-chip-list-in-material-angular-6 */
atlas-numeric-chip-list div.mat-chip-list-wrapper {
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;

  .mat-chip {
    flex: 0 0 auto;
  }
}

atlas-locator-catchment-chip-list div.mat-chip-list-wrapper {
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;

  .mat-chip {
    flex: 0 0 auto;
  }
}

atlas-scenario-name-chip-list div.mat-chip-list-wrapper {
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;

  .mat-chip {
    flex: 0 0 auto;
  }
}

atlas-numeric-chip-list .mat-chip-list-wrapper input.mat-input-element {
  margin: 4px 0px !important;
}

atlas-locator-catchment-chip-list
  .mat-chip-list-wrapper
  input.mat-input-element {
  margin: 4px 0px !important;
}

atlas-scenario-name-chip-list .mat-chip-list-wrapper input.mat-input-element {
  margin: 4px 0px !important;
}

atlas-numeric-chip-list input.mat-chip-input {
  width: 100% !important;
  margin: 4px !important;
  flex: 1 0 !important;
}

atlas-locator-catchment-chip-list input.mat-chip-input {
  width: 100% !important;
  margin: 4px !important;
  flex: 1 0 !important;
}

atlas-scenario-name-chip-list input.mat-chip-input {
  width: 100% !important;
  margin: 4px !important;
  flex: 1 0 !important;
}

atlas-numeric-chip-list
  .mat-form-field-appearance-outline
  .mat-form-field-flex {
  min-height: 48px;
}

atlas-locator-catchment-chip-list
  .mat-form-field-appearance-outline
  .mat-form-field-flex {
  min-height: 48px;
}

atlas-scenario-name-chip-list
  .mat-form-field-appearance-outline
  .mat-form-field-flex {
  min-height: 48px;
}

.mdc-evolution-chip-set .mdc-evolution-chip {
  margin: 0px !important;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 18px !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action
  .mdc-evolution-chip__action--trailing {
  padding-left: 0px !important;
}

.mdc-evolution-chip-set__chips {
  gap: 4px !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
  .mdc-evolution-chip__text-label {
  color: @lead-accent !important;
  font-weight: bold;
}

.mat-mdc-form-field:not(.mat-form-field-disabled)
  .mat-mdc-floating-label.mdc-floating-label {
  font-size: 12px;
}
.mat-mdc-form-field-hint-wrapper {
  padding: 0px 0px !important;
}
